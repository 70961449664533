import {
  Grid,
  Paper,
  CssBaseline,
  Box
} from '@mui/material';
import { Link as RouterLink, Outlet } from 'react-router-dom';
import { APP_NAME, BASE_IMAGE} from '../../config/main.json';

const logo = `${BASE_IMAGE}logo-dark.png`


const SigninLayout = () => (
  <Grid container component="main" sx={{ height: '100vh' }}>
    <CssBaseline />
    <Grid
      item
      xs={false}
      sm={4}
      md={7}
      sx={{
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: (theme) => theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    />
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Box
        sx={{
          padding: (theme) => theme.spacing(8, 4),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <RouterLink to="/">
          <img
           src={logo}
            alt={APP_NAME}
            style={{ height: '50px' }}
          />
        </RouterLink>
        <Outlet />
      </Box>
    </Grid>
  </Grid>
);

export default SigninLayout;
