import {
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Modal,
  IconButton,
  TableCell,
  TableRow,
  CircularProgress,
  Backdrop,
  Grid,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import TaskForm from "../../components/task/TaskForm";
import Fade from "@material-ui/core/Fade";
import utils from "../../utils/utils";
import PreviewIcon from "@mui/icons-material/Preview";
import axios from "axios";
import StatusDropdown from "../../components/task/StatusDropdown";
import { API_HOST, PLATFORM_NAME, LOCALAPI_HOST } from "../../config/main.json";
import PostReview from "../../components/posts/PostReview";
import enums from "../../utils/enums";
import { useDispatch, useSelector } from "react-redux";
import CustomTabs from "../../common/CustomTabs/CustomTabs";
import campaignTabsArray from "../../utils/campaignTabsArray";
import Loader from "./Loader";

import { textColor, snapshotHeaderIcons } from "../../components/campaignAnalysis/snapshot";
import CampaignActions from "./CampaignActions";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Tasks() {
  const [cid, setCid] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [open, setOpen] = useState(false);
  const [linkInfo_tableData, setLinkInfo_tableData] = useState([]);
  const [linkInfo_tableOptions, setLinkInfo_tableOptions] = useState([]);
  const [linkInfo_tableColumns, setLinkInfo_tableColumns] = useState([]);
  const [backdropShow, setBackdropShow] = useState(false);
  const [formType, setFormType] = useState("Create");
  const [taskId, setTaskId] = useState(0);
  const [postIdSelected, setPostIdSelected] = useState();
  const [postReviewModalDisplay, setPostReviewModalDisplay] = useState(false);
  const [postDetail, setPostDetail] = useState({});
  const [expandedAttachmentsModalDisplay, setExpandedAttachmentsModalDisplay] = useState(false);
  const [tabArray, setTabArray] =  useState([]);
  const [loding, setLoding] = useState(true);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const reviewPost = (postId) => {
    setPostIdSelected(postId);
    axios.get(API_HOST + "/post/details/" + postId).then((res) => {
      const postDetail = res.data;
      if (postDetail != null) {
        setPostDetail(postDetail);
        setPostReviewModalDisplay(true);
      }
    });
  };

  const addPostReview = (status, comment, postContent) => {
    const notificationMetadata = {
      userId: postDetail.iid.toString(),
      sourcePlatform: PLATFORM_NAME,
      notificationTypeId: "4",
      notificationActionId: "2",
      notificationCategoryId: "6",
      generatedBy: user.attributes.name,
      priority: "medium",
      consumedBy: "gazelle",
      navigateTo: `/account/posts/draft/${postIdSelected}?campaignId=${postDetail.cid}`,
    };
    const data = {
      postId: postIdSelected,
      status,
      comment,
      postContent,
      campaignName: postDetail.campaignName,
      platform: "oryx",
      email: user.attributes.email,
      notificationMetadata,
    };

    const optionAxios = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };
    axios
      .post(API_HOST + "/posts/review", data, optionAxios)
      .then((res) => {
        dispatch(
          openSnackbar({
            message: res.data.Msg,
            severity: "success",
          })
        );
      })
      .catch((_res) => {
        dispatch(
          openSnackbar({
            message: "Could not update post status",
            severity: "error",
          })
        );
      });
  };

  /* post review actions */
  const handleActionButtonClick = (event) => {
    const status = enums.statusMapping[event.action];
    const comment = event.feedback;
    const postContent = event.postContent;
    addPostReview(status, comment, postContent);
  };

  const attachmentsInFullScreen = () => {
    setExpandedAttachmentsModalDisplay(true);
  };

  const handlePostReviewModalClose = () => {
    setPostReviewModalDisplay(false);
  };

  const styles = {
    noData: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      fontSize: "large",
      fontStyle: "italic",
    },
  };
  const linkInfo_multiCableColumns = [
    {
      name: "expansion",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      label: "Title",
      name: "taskName",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <IconButton>
                <Typography sx={textColor[value.platform?.toLowerCase()]}>
                  {snapshotHeaderIcons[value.platform?.toLowerCase()]}
                </Typography>
              </IconButton>
              {value.taskName}
            </div>
          );
        },
      },
    },
    {
      label: "TaskId",
      name: "taskId",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      label: "Milestone",
      name: "milestone",
    },
    {
      label: "list",
      name: "listName",
    },
    {
      label: "Total Influencers",
      name: "infCount",
    },
    {
      label: "Due Date",
      name: "dueDate",
    },
    {
      label: "Priority",
      name: "priority",
    },
    {
      label: "Actions",
      name: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex; // Get the index of the clicked row
          const taskId = tableMeta.tableData[rowIndex][2]; // Assuming the taskId is in the first column
          return (
            <div>
              <IconButton
                id={"Edit-" + taskId}
                onClick={() => {
                  handleEditOpen(taskId);
                }}
                // onClick={this.handleClickOpen.bind(this, tableMeta.rowData[0] - 1)}
              >
                <Tooltip title={"Edit"}>
                  <PreviewIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
        filter: false,
      },
    },
  ];
  const subTableOptions = {
    responsive: "simple",
    selectableRows: "none",
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    filter: false,
    pagination: false,
  };

  const handleStatusChange = (newValue, rowId, tiId) => {
    setIsLoaded(true);
    setBackdropShow(true);
    // Update the status in your data source (e.g., API call or state update)
    // For demonstration purposes, we'll update the local data array here:
    const payload = { tiId: parseInt(tiId), status: newValue };
    utils
      .updateTaskStatus(payload)
      .then((data) => {
        // Handle success
        setBackdropShow(false);
        setOpen(false);
        dispatch(
          openSnackbar({
            message: "Task status updated successfully!",
            severity: "success",
          })
        );
      })
      .catch((error) => {
        // Handle error
        setBackdropShow(false);
        setOpen(false);
        dispatch(
          openSnackbar({
            message: error,
            severity: "error",
          })
        );
        // You can show an error message or take other actions here
      });
    // You can use updatedData to update your data source or state
  };

  const subColumn = [
    {
      label: "Influencer Name",
      name: "infName",
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <StatusDropdown
              value={value}
              rowId={tableMeta.rowIndex}
              tiId={tableMeta.rowData[3]} // Assuming tiId is the third column
              onChange={handleStatusChange}
            />
          );
        },
        sort:false
      },
    },
    {
      label: " ",
      name: "postId",
      options: {
        filter: false,
        download: false,
        viewColumns: false,
        sort: false,
        customBodyRender: (_value, { rowData }, _updateValue) => {
          /* get postId here */
          const [_, status, postId] = rowData; // Destructuring rowData

          if (!postId) {
            return (
              <div className="actionColumn">
                <Grid item xs={6}>
                  <Tooltip title={"The influencer has not submitted a post yet."}>
                    <Button
                      variant="outlined"
                      size="small"
                      style={{
                        borderRadius: "22.5px",
                        color: "#464e5f",
                        cursor: "help",
                      }}
                    >
                      Waiting for a post
                    </Button>
                  </Tooltip>
                </Grid>
              </div>
            );
          }
          const buttonText = status === "Reassigned" ? "View Original Draft" : "View Draft";
          return (
            <div className="actionColumn">
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{
                    borderRadius: "22.5px",
                    color: "#464e5f",
                  }}
                  onClick={() => reviewPost(postId)}
                >
                  {buttonText}
                </Button>
              </Grid>
            </div>
          );
        },
      },
    },
    {
      name: "tiId", // Assuming tiId is the name of the column containing tiId
      options: {
        display: "excluded", // Exclude this column from the table display
      },
    },
  ];

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto", // Adjust the width as needed
    backgroundColor: "white",
    boxShadow: 24,
  };

  const linkInfo_multiTableOptions = {
    filterType: "dropdown",
    expandableRows: true,
    expandableRowsOnClick: true,
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
    },
    responsive: "simple",
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchPlaceholder: "Search by all visible columns",
    print: false,
    renderExpandableRow: (rowData, _rowMeta) => {
      return rowData[0];
    },
  };

  const getLinkInfo = (campId) => {
    let linkInfo_tableOptions, linkInfo_tableColumns;

    linkInfo_tableOptions = linkInfo_multiTableOptions;
    linkInfo_tableColumns = linkInfo_multiCableColumns;
    utils.getTasksByCid(campId).then((data) => {
      let linkInfo_tableData = [];
      const tableData = data ? data : [];
      setLoding(false)
      const taskObject = {};
      tableData.forEach((row) => {
        if (!taskObject[row.id]) {
          taskObject[row.id] = [];
        }

        taskObject[row.id].push(row);
      });
      Object.keys(taskObject).forEach((id) => {
        const table = {};
        table.taskId = id;
        table.taskName = {
          taskName: taskObject[id][0].title,
          platform: taskObject[id][0].platform,
        };
        table.milestone = taskObject[id][0].milestone;
        table.dueDate = taskObject[id][0].enddate;
        table.priority = taskObject[id][0].priority;
        table.infCount = taskObject[id].length === 1 && taskObject[id][0].iid === 0 ? 0 : taskObject[id].length;
        table.listName = taskObject[id][0].listName;

        const data = [];
        taskObject[id].forEach((row) => {
          if (row.iid > 0) {
            data.push(row);
          }
        });
        table.expansion = (
          <TableRow
            sx={{
              ".MuiPaper-root": {
                boxShadow: "none",
              },
            }}
            style={{
              backgroundColor: "#f6f1f1",
              width: "90%",
              alignContent: "center",
            }}
          >
            {data.length > 0 && (
              <TableCell colSpan={12} sx={{ padding: "0px 0px 0px 24px" }}>
                <MUIDataTable data={data} columns={subColumn} options={subTableOptions} />
              </TableCell>
            )}
          </TableRow>
        );
        table.data = data;
        linkInfo_tableData.push(table);
      });
      setLinkInfo_tableData(linkInfo_tableData);
      setLinkInfo_tableOptions(linkInfo_tableOptions);
      setLinkInfo_tableColumns(linkInfo_tableColumns);
    });
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleEditOpen = (taskId) => {
    setFormType("View");
    setTaskId(taskId);
    handleOpen();
  };
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const campId = params.get("id");
    const tabArr = campaignTabsArray(campId);

    setCid(campId);
    setTabArray(tabArr);
    getCampaignInfo(campId);
    getLinkInfo(campId);
  }, [cid, open]);

  const getCampaignInfo = (id) => {
    utils.getCampaignData(id).then((data) => {
      setCampaignName(data.name);
    });
  };

  const tabArray2 = [
    {
      label: "Tasks",
      id: "tasks-tab-0",
      to: `/campaigns/tasks?id=${cid}`,
      component: Link,
    },
  ]

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: "column",
        }}
        open={backdropShow}
      >
        <CircularProgress color="inherit" />
        <Box sx={{ marginTop: "20px" }}>Updating a task status... please wait.</Box>
      </Backdrop>

      <CampaignActions name={campaignName} id={cid} />
      <br />
      <CustomTabs tabArray={tabArray} selectedTab={"8"} />

      <Box m={3}>
      <CustomTabs tabArray={tabArray2} selectedTab={"0"} />

        <Stack direction="row" justifyContent="end">
          <Tooltip title={"Create a task"}>
            <div>
              <Button
                sx={{ marginRight: "30px" }}
                variant="contained"
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={() => {
                  setFormType("Create");
                  handleOpen();
                }}
              >
                {" "}
                Create Task
              </Button>
            </div>
          </Tooltip>
        </Stack>
      </Box>
      {loding ? (
        <Loader />
      ) : (
        <MUIDataTable data={linkInfo_tableData} columns={linkInfo_tableColumns} options={linkInfo_tableOptions} />
      )}

      <Modal open={open} onClose={handleClose}>
        <Fade in={open} timeout={500}>
          <Box sx={modalStyle}>
            <TaskForm
              cid={cid}
              handleClose={handleClose}
              open={open}
              setOpen={setOpen}
              formType={formType}
              setFormType={setFormType}
              taskId={taskId}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        className="styles_modal"
        aria-labelledby="modal-post_review-label"
        aria-describedby="modal-post_review-description"
        open={postReviewModalDisplay}
        onClose={handlePostReviewModalClose}
        closeAfterTransition
      >
        <>
          <Fade in={postReviewModalDisplay} timeout={0}>
            <PostReview
              postDetail={postDetail}
              attachmentsInFullScreen={attachmentsInFullScreen}
              HandlePostReviewModalClose={handlePostReviewModalClose}
              HandleActionButtonClick={handleActionButtonClick}
              data = {linkInfo_tableData}
            ></PostReview>
          </Fade>
        </>
      </Modal>
    </>
  );
}

export default Tasks;