const getTableHeaders = [
  {
    id: 1,
    label: "Campaign",
    sortParameter: "name",
    headerAssignment: "name",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 2,
    label: "Posts",
    sortParameter: "postCount",
    headerAssignment: "postCount",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 3,
    label: "Influencers",
    sortParameter: "influencerCount",
    headerAssignment: "influencerCount",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 4,
    label: "Eng. This Week",
    headerAssignment: "weekEngg",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 5,
    label: "Days Left",
    sortParameter: "endDate",
    headerAssignment: "endDate",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 6,
    label: "Status",
    sortParameter: "current_status",
    headerAssignment: "current_status",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 7,
    label: "Actions",
    headerAssignment: "action",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
];


const gettableHeadersArchived = [
  {
    id: 1,
    label: "Campaign",
    sortParameter: "name",
    headerAssignment: "name",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 2,
    label: "End date",
    sortParameter: "endDate",
    headerAssignment: "endDate",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 3,
    label: "Status",
    sortParameter: "current_status",
    headerAssignment: "current_status",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },

  {
    id: 4,
    label: "Action",
    headerAssignment: "action",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
];

const getAccountsTableHeaders = [
  {
    id: 1,
    label: "Account Name",
    headerAssignment: "name",
    sortParameter: "a.name",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 2,
    label: "Business Type",
    headerAssignment: "businessType",
    sortParameter: "businessType",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 3,
    label: "No Of Brands",
    headerAssignment: "noOfBrands",
    sortParameter: "bd.brandCount",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 4,
    label: "No. Of Campaigns",
    headerAssignment: "noOfCampaigns",
    sortParameter: "cd.campaignCount",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 5,
    label: "Updated At",
    headerAssignment: "updatedAt",
    sortParameter: "updatedAt",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 6,
    label: "Plan Subscribed",
    headerAssignment: "planSubscribed",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 7,
    label: "Action",
    headerAssignment: "action",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
]

const getBrandsTableHeaders = [
  {
    id: 1,
    label: "Brand Name",
    headerAssignment: "name",
    sortParameter: "b.name",
    headeralignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 2,
    label: "Account Name",
    headerAssignment: "accountName",
    sortParameter: "a.name",
    headeralignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 3,
    label: "No. Of Campaigns",
    headerAssignment: "noOfCampaigns",
    sortParameter: "COUNT(c.name)",
    headeralignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 4,
    label: "Updated 0n",
    headerAssignment: "updatedAt",
    sortParameter: "b.updatedAt",
    headeralignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 5,
    label: "Action",
    headerAssignment: "action",
    headeralignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
]

const getListTableHeaders = [
  {
    id: 1,
    label: "",
    headerAssignment: "sl",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    checkbox: true,
    sortable: false,
  },
  {
    id: 2,
    label: "Name",
    headerAssignment: "name",
    sortParameter: "l.name",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 3,
    label: "Campaign",
    headerAssignment: "cname",
    sortParameter: "l.cname",
    headeralignment: "left",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 4,
    label: "Influencers",
    headerAssignment: "influencersCnt",
    sortParameter: "l.infCount",
    headeralignment: "center",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 5,
    label: "Publications",
    headerAssignment: "postCount",
    sortParameter: "count(lp.iid)",
    headeralignment: "right",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 6,
    label: "List Status",
    headerAssignment: "status",
    sortParameter: "l.status",
    headeralignment: "center",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 7,
    label: "Created On",
    headerAssignment: "createdOn",
    sortParameter: "createdOn",
    headeralignment: "center",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 9,
    label: "Action",
    headerAssignment: "Action",
    headeralignment: "left",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
];


const getListTableHeadersArchived = [
  {
    id: 1,
    label: "Name",
    headerAssignment: "name",
    sortParameter: "l.name",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 2,
    label: "Campaign",
    headerAssignment: "cname",
    sortParameter: "l.cname",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 3,
    label: "Influencers",
    headerAssignment: "influencersCnt",
    sortParameter: "l.infCount",
    headeralignment: "left",
    alignment: "left",
    numeric: true,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 4,
    label: "Publications",
    headerAssignment: "postCount",
    sortParameter: "count(lp.iid)",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 5,
    label: "Created On",
    headerAssignment: "createdOn",
    sortParameter: "createdOn",
    headeralignment: "center",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 6,
    label: "Action",
    headerAssignment: "Action",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
];


const getInfluencersTableHeaders = [
  {
    id: 1,
    label: "Name",
    headerAssignment: "name",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 2,
    label: "Location",
    headerAssignment: "location",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 3,
    label: "Gender",
    headerAssignment: "gender",
    // headeralignment: "left",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 4,
    label: "Age",
    headerAssignment: "ageRange",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 5,
    label: "Followers",
    headerAssignment: "followers",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 6,
    label: "Publications",
    headerAssignment: "postCount",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 7,
    label: "Engagement",
    headerAssignment: "engagement",
    // headeralignment: "left",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 8,
    label: "ER",
    headerAssignment: "engagementRate",
    headeralignment: "center",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 9,
    label: "",
    headerAssignment: "options",
    headeralignment: "left",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
];

const getPublishedPostAnalysisTableHeaders = [
  {
    id: 1,
    label: " ",
    headerAssignment: "expandedRow",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    checkbox: true,
    sortable: false,
  },
  {
    id: 2,
    label: "Post URL",
    headerAssignment: "post_URL",
    sortParameter: "postUrl",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 3,
    label: " ",
    headerAssignment: "tagData",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 4,
    label: "Post Type",
    headerAssignment: "postType",
    sortParameter: "postType",
    alignment: "left",
    numeric: false,
    percentage: false,
    sortable: false,
    show: true,
  },
  {
    id: 5,
    label: "Published Date",
    headerAssignment: "publishedAt",
    sortParameter: "a.postedOn",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 6,
    label: "Task",
    headerAssignment: "taskName",
    sortParameter: "title",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 7,
    label: "Milestone",
    headerAssignment: "milestone",
    sortParameter: "milestone",
    // headeralignment: "left",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 8,
    label: "Total Engagement",
    headerAssignment: "totalEngagement",
    headeralignment: "center",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 9,
    label: "Actual Reach",
    headerAssignment: "actuallReach",
    headeralignment: "left",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 10,
    label: "Potential Reach",
    headerAssignment: "potentialReach",
    headeralignment: "left",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 11,
    label: "Eng. Rate",
    headerAssignment: "engagementRate",
    headeralignment: "left",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 12,
    label: "Status",
    headerAssignment: "status",
    sortParameter: "status",
    headeralignment: "left",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 13,
    label: "List Name",
    headerAssignment: "lisName",
    sortParameter: "groupName",
    headeralignment: "left",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 14,
    label: "Last Refreshed",
    headerAssignment: "lastRefreshed",
    sortParameter: "createdAt",
    headeralignment: "left",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 15,
    label: "Actions",
    headerAssignment: "actions",
    headeralignment: "left",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  }
];


const getAccountCampignsTableHeaders = [
  {
    id: 1,
    label: "Name",
    headerAssignment: "name",
    sortParameter: "name",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 2,
    label: "End Date",
    sortParameter: "endDate",
    headerAssignment: "endDate",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 3,
    label: "Influencer",
    sortParameter: "influencerCount",
    headerAssignment: "creatorAccounts",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 4,
    label: "Total Publications ",
    sortParameter: "postCount",
    headerAssignment: "totalPublications",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 5,
    label: "Status",
    sortParameter: "current_status",
    headerAssignment: "current_status",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
];

const getAccountBrandsTableHeaders = [
  {
    id: 1,
    label: "Name",
    headerAssignment: "name",
    sortParameter: "b.name",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 2,
    label: "Created",
    headerAssignment: "created",
    sortParameter: "b.updatedAt",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 3,
    label: "Campaigns",
    headerAssignment: "campaigns",
    sortParameter: "COUNT(c.name)",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 4,
    label: "Category",
    headerAssignment: "category",
    sortParameter: "category",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 5,
    label: " ",
    headerAssignment: "edit",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
];

const getDraftsToReviesPostTableHeaders = [
  {
    id: 1,
    label: "Influencer",
    sortParameter: "influencer",
    headerAssignment: "influencer",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 2,
    label: "Task",
    sortParameter: "t.title",
    headerAssignment: "taskName",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 3,
    label: "Milestone",
    sortParameter: "m.milestone",
    headerAssignment: "milestone",
    alignment: "left",
    numeric: false,
    percentage: false,
    sortable: true,
    show: true,
  },
  {
    id: 4,
    label: "Status",
    sortParameter: "a.currentStatus",
    headerAssignment: "status",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 5,
    label: "Action",
    headerAssignment: "action",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
];

const getApprovedPostsTableHeaders = [
  {
    id: 1,
    label: "Influencer",
    headerAssignment: "influencer",
    sortParameter: "influencer",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 2,
    label: "Task",
    sortParameter: "t.title",
    headerAssignment: "taskName",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 3,
    label: "Milestone",
    headerAssignment: "milestone",
    sortParameter: "m.milestone",
    alignment: "left",
    numeric: false,
    percentage: false,
    sortable: true,
    show: true,
  },
  {
    id: 4,
    label: "Approved",
    headerAssignment: "approved",
    sortParameter: "approved",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 5,
    label: "Action",
    headerAssignment: "action",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
];

const getDiscardedPostTableHeaders = [
  {
    id: 1,
    label: "Influencer",
    headerAssignment: "influencer",
    sortParameter: "influencer",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 2,
    label: "Task",
    headerAssignment: "taskName",
    sortParameter: "t.title",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 3,
    label: "Milestone",
    sortParameter: "m.milestone",
    headerAssignment: "milestone",
    alignment: "left",
    numeric: false,
    percentage: false,
    sortable: true,
    show: true,
  },
  {
    id: 4,
    label: "Discarded",
    sortParameter: "discarded",
    headerAssignment: "discarded",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 5,
    label: "Action",
    headerAssignment: "action",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
];

const getUserAccessTableHeaders = [
  {
    id: 1,
    label: <span style={{ marginLeft: "80px" }}>Name</span>,
    sortParameter: "name",
    headerAssignment: "name",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 2,
    label: "Company Name",
    sortParameter: "companyName",
    headerAssignment: "companyName",
    headeralignment: "left",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 3,
    label: "Status",
    sortParameter: "status",
    headerAssignment: "status",
    headeralignment: "center",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 4,
    label: "Role",
    sortParameter: "role",
    headerAssignment: "role",
    headeralignment: "center",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 5,
    label: "",
    headerAssignment: "actions",
    alignment: "right",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
];

const getClientAccessTableHeaders = [
  {
    id: 1,
    label: <span style={{ marginLeft: "80px" }}>Name</span>,
    sortParameter: "name",
    headerAssignment: "name",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 2,
    label: "Company Name",
    sortParameter: "companyName",
    headerAssignment: "companyName",
    headeralignment: "left",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 3,
    label: "Last Login",
    sortParameter: "lastLoggedIn",
    headerAssignment: "lastLoggedIn",
    headeralignment: "left",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 4,
    label: "Added By",
    sortParameter: "addedBy",
    headerAssignment: "addedBy",
    headeralignment: "center",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 5,
    label: "Status",
    sortParameter: "status",
    headerAssignment: "status",
    headeralignment: "center",
    alignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 6,
    label: "Role",
    sortParameter: "role",
    headerAssignment: "role",
    headeralignment: "center",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 7,
    label: "",
    headerAssignment: "actions",
    alignment: "right",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
];

const getUserLogsHeaders = [
  {
    id: 1,
    label: "Time (GMT)",
    sortParameter: "time",
    headerAssignment: "time",
    headeralignment: "center",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: true,
  },
  {
    id: 2,
    label: "User Email",
    sortParameter: "email",
    headerAssignment: "email",
    headeralignment: "center",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 3,
    label: "Entity",
    sortParameter: "entity",
    headerAssignment: "entity",
    headeralignment: "center",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 4,
    label: "Action",
    headerAssignment: "action",
    headeralignment: "center",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 5,
    label: "",
    headerAssignment: "options",
    alignment: "center",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
];

const getSingleBaseAppLinktableHeaders = [
  {
    id: 1,
    label: "Influencer",
    mappedValue: "Influencer",
    sortParameter: "influencer",
    headerAssignment: "influencer",
    headeralignment: "center",
    bodyalignment: "left",
    show: true,
  },
  {
    id: 2,
    label: "Group",
    mappedValue: "Group",
    sortParameter: "group",
    headerAssignment: "group",
    headeralignment: "center",
    bodyalignment: "left",
    show: true,
  },
  {
    id: 3,
    label: "URL",
    mappedValue: "URL",
    sortParameter: "url",
    headerAssignment: "url",
    headeralignment: "center",
    bodyalignment: "center",
    show: true,
  },
  {
    id: 4,
    label: "Created On",
    mappedValue: "Created On",
    sortParameter: "created",
    headerAssignment: "created",
    headeralignment: "left",
    bodyalignment: "left",
    show: true,
  },
  {
    id: 5,
    label: "Clicks",
    mappedValue: "Clicks",
    sortParameter: "clicks",
    headerAssignment: "clicks",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: true,
    show: true,
  },
  {
    id: 6,
    label: "",
    mappedValue: "",
    sortParameter: "action",
    headerAssignment: "action",
    headeralignment: "left",
    bodyalignment: "left",
    show: true,
  },
];

const getMultipleBaseAppLinktableHeader = [
  {
    id: 1,
    label: "Destination URL",
    mappedValue: "Destination URL",
    sortParameter: "destinationUrl",
    headeralignment: "center",
    bodyalignment: "left",
    show: true,
  },
  {
    id: 2,
    label: "Group",
    mappedValue: "Group",
    sortParameter: "group",
    headerAssignment : "group",
    headeralignment: "center",
    bodyalignment: "center",
    show: true,
  },
  {
    id: 9,
    label: "Total Clicks",
    mappedValue: "Total Clicks",
    sortParameter: "totalClicks",
    headerAssignment : "totalClicks",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: true,
    show: true,
  },
  {
    id: 10,
    label: "Total Redirect",
    mappedValue: "Total Redirect",
    sortParameter: "totalRedirects",
    headerAssignment : "totalRedirects",
    headeralignment: "center",
    bodyalignment: "center",
    show: true,
  },
  {
    id: 11,
    label: "Total App-Installs",
    mappedValue: "Total App Installs",
    sortParameter: "totalApp_install",
    headerAssignment : "totalApp_install",
    headeralignment: "center",
    bodyalignment: "center",
    show: true,
  },
  {
    id: 12,
    label: "Total App First Open",
    mappedValue: "Total App First Open",
    sortParameter: "totalApp_first_open",
    headerAssignment : "totalApp_first_open",
    headeralignment: "center",
    bodyalignment: "center",
    show: true,
  },
  {
    id: 13,
    label: "Total App Re Open",
    mappedValue: "Total App Re Open",
    sortParameter: "totalApp_re_open",
    headerAssignment : "totalApp_re_open",
    headeralignment: "center",
    bodyalignment: "center",
    show: true,
  },
];

const getInfluencerTableHeaders = [
  {
      id: 1,
      label: "",
      headerAssignment: "sl",
      alignment: "left",
      numeric: false,
      percentage: false,
      show: true,
      checkbox: true,
      sortable: false,
  },
  {
    id: 2,
    label: "",
    headerAssignment: "profile",
    sortParameter: "profile",
    headeralignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 3,
    label: "Followers",
    headerAssignment: "total_followers",
    sortParameter: "total_followers",
    headeralignment: "left",
    numeric: true,
    percentage: false,
    show: true,
    sortable: false,
  },
  // {
  //   id: 4,
  //   label: "Following",
  //   headerAssignment: "friends_count",
  //   sortParameter: "friends_count",
  //   headeralignment: "left",
  //   numeric: true,
  //   percentage: false,
  //   show: true,
  //   sortable: false,
  // },
  {
    id: 5,
    label: "Age",
    headerAssignment: "age_range",
    sortParameter: "age_range",
    headeralignment: "left",
    numeric: true,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 6,
    label: "Gender",
    headerAssignment: "gender",
    sortParameter: "gender",
    headeralignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 7,
    label: "Location",
    headerAssignment: "location",
    sortParameter: "location",
    headeralignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 8,
    label: "Status",
    headerAssignment: "iStatus",
    sortParameter: "iStatus",
    headeralignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  {
    id: 9,
    label: "Action",
    headerAssignment: "action",
    headeralignment: "left",
    numeric: false,
    percentage: false,
    show: true,
    sortable: false,
  },
  ]


export {
  getTableHeaders,
  gettableHeadersArchived,
  getAccountsTableHeaders,
  getBrandsTableHeaders,
  getListTableHeaders,
  getListTableHeadersArchived,
  getInfluencersTableHeaders,
  getPublishedPostAnalysisTableHeaders,
  getAccountCampignsTableHeaders,
  getAccountBrandsTableHeaders,
  getDraftsToReviesPostTableHeaders,
  getApprovedPostsTableHeaders,
  getDiscardedPostTableHeaders,
  getUserAccessTableHeaders,
  getClientAccessTableHeaders,
  getUserLogsHeaders,
  getSingleBaseAppLinktableHeaders,
  getMultipleBaseAppLinktableHeader, 
  getInfluencerTableHeaders
};