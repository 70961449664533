import React, { Component } from "react";
import { API_HOST, INF_API_HOST, PLATFORM_NAME } from "../../../config/main.json";
import { Box, Button, Divider, Grid, TextField, Typography, CircularProgress, Backdrop, Stack, ButtonGroup } from "@mui/material";
import { BadgeTwoTone } from "@mui/icons-material";
import MyPaper from "../../general/MyPaper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "../../../styles/internal/Campaign.css";
import utils from "../../../utils/utils";
import enums from "../../../utils/enums";
import { getAllLists, getAllTasks } from "../../../services/list/ListDetails";
import { snapshotHeaderIcons, textColor } from "../../campaignAnalysis/snapshot";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import StoriesDynamicForm from "../../general/StoriesDynamicForm";
import ArticleIcon from "@mui/icons-material/Article";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import PollIcon from "@mui/icons-material/Poll";
import MicIcon from "@mui/icons-material/Mic";
import { connect } from "react-redux";
import { getNotificationType } from "../../../services/account/notification";

let backdropShow = false;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class AddPost extends Component {
  constructor(props) {
    super(props);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let campId = parseInt(params.get("id"), 10);
    this.state = {
      backdropShow: backdropShow,

      campaignSelected: campId,
      campaignName: "",
      tasks: [],
      lists: [],
      label: "Enter the list of Post Urls separated by commas or in new lines",
      placeholder: "Enter the list of Post Urls separated by commas or in new lines",
      /* for post URLs */
      listSelected_url: "",
      postUrl: "",
      warning: true,
      severity: "success",
      /* for post IDs */
      listSelected_id: "",
      influencers: [],
      influencerSelected: "",
      postTypeSelected: "",
      influencerOptionalFlag: true,
      platforms: [],
      platformSelected: "",
      postId: "",
      postTypes: [],
      storyData: [],
      notificationType: [],
      clearStoriesDataForm: false,
      platformName: "",

      /* for the snackbar */
      message: "", // message to show on the snackbar alert
      notification: false, // whether to display Snackbar alert
      refreshAfterNotification: false, // whether to refresh the page after showing notification
      warnings:
        //"Instagram analytics is currently down for new Posts due to a 3rd Party Issue. You can still add new posts and stats collection for these posts will start once the issue is fixed by our data partner. ETA: 'Few Days'",
        null,
      langList: { all: [] },
      postLang: "English",
      taskSelected_id: "",
    };
  }

  closeNotification = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ warning: false });
  };

  getTranslatorInfo = () => {
    axios.get(API_HOST + "/translator/info").then((res) => {
      this.setState({ langList: res.data.body?.listing });
    });
  };

  getNotificationType = async () => {
    const res = await getNotificationType();
    this.setState({ notificationType: res });
  };

  async getTasks() {
    const campaignId = this.state.campaignSelected;
    const data = await getAllTasks(campaignId.toString());
    this.setState({
      tasks: data,
      listSelected: "",
      influencers: [],
      influencerSelected: "",
      postTypeSelected: "",
      postTypes: [],
      backdropShow: false,
    });
  }

  async getLists() {
    const campaignId = this.state.campaignSelected;
    const data = await getAllLists(campaignId.toString(), "all");
    this.setState({
      lists: data,
      listSelected: "",
      influencers: [],
      influencerSelected: "",
      postTypeSelected: "",
      postTypes: [],
      backdropShow: false,
    });
  }

  StoriesDynamicFormCallback = (data) => {
    this.setState({ storyData: data });
  };

  getPlatforms() {
    axios.get(API_HOST + "/platform/list").then((res) => {
      const platforms = res.data;
      this.setState({ platforms: platforms });
    });
  }

  getPostTypes(id) {
    axios.get(API_HOST + "/postTypes/" + id).then((res) => {
      const postTypes = res.data;
      this.setState({
        postTypes: postTypes,
        postTypeSelected: postTypes[0].id,
      });
    });
  }

  getCampaignInfo() {
    const campaignId = this.state.campaignSelected;
    utils.getCampaignData(campaignId).then((data) => {
      this.setState({ campaignName: data.name });
    });
  }

  componentDidMount() {
    this.getTasks();
    this.getLists();
    this.getPlatforms();
    this.getCampaignInfo();
    this.getTranslatorInfo();
    this.getNotificationType();
  }

  async submitPostId(e) {
    e.preventDefault();
    const { user } = this.props;
    this.setState({
      backdropShow: true,
      clearStoriesDataForm: !this.state.clearStoriesDataForm,
    });
    const replaceAll = (str, find, replace) => str.replace(new RegExp(find, "g"), replace);
    let postIds = replaceAll(this.state.postId, ",", "\n");
    postIds = replaceAll(postIds, " ", "\n");
    postIds = replaceAll(postIds, "\t", "\n");
    postIds = postIds.split("\n");

    const regex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

    postIds = postIds.map((postId) => postId.trim()).filter((postId) => regex.test(postId));

    const images = [];
    this.state.storyData.forEach((story) => {
      if (story.image !== null) {
        images.push(story.image);
      }
    });
    const imageFormData = new FormData();
    let resp;
    if (images.length > 0) {
      images.forEach((image) => {
        imageFormData.append("attachment", image);
      });
      imageFormData.append("cid", this.state.campaignSelected);
      imageFormData.append("lid", this.state.listSelected_id);
      imageFormData.append("iid", this.state.influencerSelected);

      resp = await axios.post(API_HOST + "/post/story/upload", imageFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    }

    let i = 0;
    const storyData = this.state.storyData.map((story) => {
      if (story.image === null) {
        story.image = "";
      } else {
        story.image = resp.data.Url[i++];
      }
      return story;
    });

    const search = window.location.search;
    const params = new URLSearchParams(search);
    let campId = parseInt(params.get("id"), 10);

    const postData = {
      cid: this.state.campaignSelected,
      lid: this.state.listSelected_id,
      iid: this.state.influencerSelected === "" ? 0 : this.state.influencerSelected,
      platform: this.state.platformSelected,
      postType: parseInt(this.state.postTypeSelected) > 3 ? 1 : parseInt(this.state.postTypeSelected),
      postIds: postIds,
      postLang: this.state.postLang,
      tid: this.state.taskSelected_id,
      //manually entered story data
      storyData: storyData,
      notificationMetadata: {
        userId: JSON.parse(user.attributes.identities)[0].userId,
        sourcePlatform: PLATFORM_NAME,
        notificationTypeId: "4",
        notificationActionId: "2",
        notificationCategoryId: "1",
        generatedBy: user.attributes.name,
        priority: "high",
        consumedBy: "oryx, empowr",
        navigateTo: "/campaigns/postAnalysis?id=" + campId,
      },
    };

    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    let msg = "Error. Please try again!";
    let severity = "error"
    /* send postIds to the backend */
    axios
      .post(API_HOST + "/post/add", postData, optionAxios)
      .then((res) => {
        console.log(res)
        if (res.status === 200 && res.data.status === "success") {
          msg = "Post Urls added successfully!";
          severity = "success"
        } else {
          msg = res.data.status;
          severity = "error"
        }
      })
      .catch((res) => {
        console.log("Some error occurred: ", res);
      })
      .finally(() => {
        this.setState({
          influencerSelected: "",
          postId: "",
          message: msg,
          severity,
          notification: true,
          refreshAfterNotification: true,
          backdropShow: false,
        });
      });
  }

  handlListSelection_url(field, e) {
    const listSelected_url = e.target.value;
    this.setState({ listSelected_url });
  }

  handlListSelection_id(field, e) {
    let lid_platform = e.target.value;
    const data = lid_platform.split(" - ");
    let platformName,
      taskSelected_id = "";
    if (!parseInt(data[1], 10)) {
      platformName = data[1];
    } else {
      taskSelected_id = parseInt(data[1], 10);
      platformName = data[2];
      this.setState({ taskSelected_id });
    }
    let listSelected_id = parseInt(data[0], 10);
    this.setState({
      listSelected_id,
      influencerSelected: "",
      postTypeSelected: "",
      platformName,
    });
    this.state.platforms.forEach((values, keys) => {
      if (values["name"] === platformName) {
        const platformSelected = values["id"];
        this.setState({
          label: "Enter the list of Post Urls separated by commas or in new lines",
          placeholder: "Example: " + enums.postlinkExample[platformSelected],
        });
        this.setState({ platformSelected });
        this.getPostTypes(values["id"]);
      }
    });
    var organicIdsArray = [];
    var directIdsArray = [];
    /* get influencers for this list */
    axios.get(API_HOST + "/list/influencers/id/" + listSelected_id).then((res) => {
      if (res.data == null || res.data.length === 0) {
        let influencers = [];
        /*let listSelected_id = "";
        this.setState({ influencers, listSelected_id });*/ // Commented this, because lid was not comming for newly created list
        this.setState({ influencers });
        return;
      }

      res.data.map((influencer) => {
        if (influencer["type"] === "1") {
          directIdsArray.push(parseInt(influencer["iid"], 10));
        } else {
          organicIdsArray.push(parseInt(influencer["iid"], 10));
        }
      });
      const optionAxios = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      /* get influencers details */
      axios
        .post(
          `${INF_API_HOST}/influencer/specific/list`,
          {
            organicIds: organicIdsArray.join(","),
            directIds: directIdsArray.join(","),
            platform: platformName,
          },
          optionAxios
        )
        .then((res) => {
          let influencers = res["data"];
          if (influencers == null) {
            influencers = [];
            //listSelected_id = "";
          }
          /* this.setState({
            listSelected_id,
            influencers,
          }); */ // Commented this, because lid was not comming for newly created list
          this.setState({ influencers });
        })
        .catch((err) => {
          console.log("error: ", err);
        });
    });
  }

  handleInfluencerSelection(field, e) {
    const influencerSelected = e.target.value;
    this.setState({ influencerSelected });
  }
  handleInPostTypeSelection(field, e) {
    const postTypeSelected = e.target.value;
    this.setState({ postTypeSelected });

    //Influencer mandatory for stories and reels
    if (postTypeSelected != "1" && postTypeSelected !== "2") {
      this.setState({
        influencerOptionalFlag: false,
      });
    } else {
      this.setState({
        influencerOptionalFlag: true,
      });
    }
  }

  handlePostURLChange(field, e) {
    const postUrl = e.target.value;
    this.setState({ postUrl });
  }

  handlePostIdChange(field, e) {
    const postId = e.target.value;
    this.setState({ postId });
  }

  render() {
    const closeNotification = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      if (this.state.refreshAfterNotification) {
        this.setState({ notification: false, refreshAfterNotification: false });
        // window.location.reload();
      } else {
        this.setState({ notification: false, refreshAfterNotification: false });
      }
    };

    return (
      <div>
        <Snackbar
          open={this.state.notification}
          autoHideDuration={3000}
          onClose={closeNotification}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={closeNotification} severity={this.state.severity} sx={{ width: "100%" }}>
            {this.state.message}
          </Alert>
        </Snackbar>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            flexDirection: "column",
          }}
          open={this.state.backdropShow}
        >
          <CircularProgress color="inherit" />
          <Box sx={{ marginTop: "20px" }}>Submitting data... please wait.</Box>
        </Backdrop>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: "none" }}>
            <MyPaper>
              <Typography variant="h5" sx={{ mb: 2 }}>
                <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
                Using Post URLs
              </Typography>
              <Divider />

              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-standard-label"> List </InputLabel>
                    <Select
                      required={true}
                      variant="outlined"
                      label="List"
                      id="select-list"
                      value="this.state.listSelected_url"
                      onChange={this.handlListSelection_url.bind(this, "listSelected_url")}
                      refs="this.state.listSelected_url"
                    >
                      {this.state.lists.map((list) => (
                        <MenuItem key={list.id + " - " + list.platform} value={list.id + " - " + list.platform}>
                          <Typography sx={textColor[list.platform]}>
                            {snapshotHeaderIcons[list.platform]}
                            &nbsp;&nbsp;
                            <span
                              style={{
                                color: "black",
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "14px",
                              }}
                            >
                              {list.name}
                            </span>
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <br />

                <Grid item xs={12} sm={12}>
                  <TextField
                    refs="postURL"
                    value={this.state.postURL}
                    onChange={this.handlePostURLChange.bind(this, "postURL")}
                    multiline
                    variant="outlined"
                    label="Post URLs"
                    rows={5}
                    sx={{ width: "100%" }}
                    required={true}
                  />
                </Grid>

                <br />

                <Grid item xs={12} sx={{ textAlign: "right" }}>
                  <Box sx={{ mt: 4 }}>
                    <Button variant="contained" size="large" type="submit">
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </MyPaper>
          </Grid>

          <br />

          <Grid item xs={12}>
            <Box m={3}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h2">{this.state.campaignName}</Typography>
              </Stack>
            </Box>
            <MyPaper>
              <Typography variant="h5" sx={{ mb: 2 }}>
                <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
                Adding list of posts
              </Typography>
              <Divider />
              {/* warnings, if any */}
              {/* {this.state.warning && (
                <div>
                  <Alert
                    sx={{
                      width: "70%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      backgroundColor: "green",
                    }}
                    onClose={this.closeNotification}
                    severity="warning"
                    variant="filled"
                  >
                    {this.state.warnings}
                  </Alert>
                  <br />
                </div>
              )} */}

              <form onSubmit={this.submitPostId.bind(this)}>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {this.state.tasks.length > 0 && (
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-standard-label">Task</InputLabel>
                        <Select
                          required={true}
                          variant="outlined"
                          label="Task"
                          id="select-task"
                          value={this.state.listSelected_id + " - " + this.state.taskSelected_id + " - " + this.state.platformName}
                          onChange={this.handlListSelection_id.bind(this, "listSelected_id")}
                        >
                          {this.state.tasks?.map((task) => (
                            <MenuItem
                              key={task.lid + " - " + task.id + " - " + task.platform}
                              value={task.lid + " - " + task.id + " - " + task.platform}
                            >
                              <Typography sx={textColor[task.platform]}>
                                {snapshotHeaderIcons[task.platform]}
                                &nbsp;&nbsp;
                                <span
                                  style={{
                                    color: "black",
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "14px",
                                  }}
                                >
                                  {task.title}
                                </span>
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {this.state.tasks.length === 0 && (
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-standard-label">List</InputLabel>
                        <Select
                          required={true}
                          variant="outlined"
                          label="List"
                          id="select-list"
                          // value="this.state.listSelected_id"
                          onChange={this.handlListSelection_id.bind(this, "listSelected_id")}
                          refs="this.state.listSelected_id"
                        >
                          {this.state.lists.map((list) => (
                            <MenuItem key={list.id + " - " + list.platform} value={list.id + " - " + list.platform}>
                              <Typography sx={textColor[list.platform]}>
                                {snapshotHeaderIcons[list.platform]}
                                &nbsp;&nbsp;
                                <span
                                  style={{
                                    color: "black",
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "14px",
                                  }}
                                >
                                  {list.name}
                                </span>
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {/* <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Post type
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value={this.state.postTypeSelected}
                        onChange={this.handleInPostTypeSelection.bind(
                          this,
                          "listSelected_id"
                        )}
                      >
                        <div>
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Post"
                          />
                          <ArticleIcon
                            color="primary"
                            sx={{
                              marginLeft: "-15px",
                            }}
                          />
                        </div>
                        {this.state.platformSelected === 2 ? (
                          <>
                            <div>
                              <FormControlLabel
                                value="9"
                                control={<Radio />}
                                label="Poll"
                              />
                              <PollIcon
                                color="primary"
                                sx={{
                                  marginLeft: "-15px",
                                }}
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                value="10"
                                control={<Radio />}
                                label="Space"
                              />
                              <MicIcon
                                color="primary"
                                sx={{
                                  marginLeft: "-15px",
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {this.state.platformSelected === 1 ||
                        this.state.platformSelected === 6 ? (
                          <>
                            <div>
                              <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label="Reel"
                              />
                              <VideocamIcon
                                color="primary"
                                sx={{
                                  marginLeft: "-15px",
                                }}
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                value="3"
                                control={<Radio />}
                                label="Story"
                              />
                              <HistoryToggleOffIcon
                                color="primary"
                                sx={{
                                  marginLeft: "-15px",
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </RadioGroup>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-standard-label">
                        {this.state.influencerOptionalFlag ? "Influencer (Optional)" : "Influencer"}
                      </InputLabel>
                      <Select
                        required={this.state.influencerOptionalFlag ? false : true}
                        variant="outlined"
                        label={this.state.influencerOptionalFlag ? "Influencer (Optional)" : "Influencer"}
                        id="select-influencer"
                        // value="this.state.influencerSelected"
                        onChange={this.handleInfluencerSelection.bind(this, "listSelected_id")}
                        value={this.state.influencerSelected}
                      >
                        {this.state.influencers.map((influencer) => (
                          <MenuItem key={influencer.influencer_id} value={influencer.influencer_id}>
                            {influencer.name ? influencer.name : influencer.first_name + " " + influencer.last_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <br />
                  <Grid item xs={12} sm={12}>
                    {this.state.postTypeSelected === "3" ? (
                      <StoriesDynamicForm
                        parentCallback={this.StoriesDynamicFormCallback}
                        clearForm={this.state.clearStoriesDataForm}
                      />
                    ) : (
                      <TextField
                        refs="postId"
                        value={this.state.postId}
                        onChange={this.handlePostIdChange.bind(this, "postId")}
                        multiline
                        variant="outlined"
                        placeholder={this.state.placeholder}
                        label={this.state.label}
                        rows={5}
                        sx={{ width: "100%" }}
                        required={true}
                        helperText="* Provide comma-separated POST URLs for the Influencer you just selected."
                      />
                    )}
                  </Grid>
                  <br />
                  <Grid item xs={12} sm={6}>
                    <FormLabel id="demo-radio-buttons-group-label">Post(s) type</FormLabel>
                    <FormControl fullWidth>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value={this.state.postTypeSelected}
                        onChange={this.handleInPostTypeSelection.bind(this, "listSelected_id")}
                      >
                        <div>
                          <FormControlLabel value="1" control={<Radio />} label="Post" />
                          <ArticleIcon
                            color="primary"
                            sx={{
                              marginLeft: "-15px",
                            }}
                          />
                        </div>
                        {this.state.platformSelected === 2 ? (
                          <>
                            <div>
                              <FormControlLabel value="9" control={<Radio />} label="Poll" />
                              <PollIcon
                                color="primary"
                                sx={{
                                  marginLeft: "-15px",
                                }}
                              />
                            </div>
                            <div>
                              <FormControlLabel value="10" control={<Radio />} label="Space" />
                              <MicIcon
                                color="primary"
                                sx={{
                                  marginLeft: "-15px",
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {this.state.platformSelected === 1 || this.state.platformSelected === 6 ? (
                          <>
                            <div>
                              <FormControlLabel value="2" control={<Radio />} label="Reel" />
                              <VideocamIcon
                                color="primary"
                                sx={{
                                  marginLeft: "-15px",
                                }}
                              />
                            </div>
                            <div>
                              <FormControlLabel value="3" control={<Radio />} label="Story" />
                              <HistoryToggleOffIcon
                                color="primary"
                                sx={{
                                  marginLeft: "-15px",
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ mt: "14px" }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-standard-label">Post(s) Language</InputLabel>
                      <Select
                        onChange={(event) => this.setState({ postLang: event.target.value })}
                        variant="outlined"
                        labelId="demo-simple-select-standard-label"
                        label="Post(s) Language"
                        value={this.state.postLang}
                      >
                        {this.state.langList?.all.map((lang) => (
                          <MenuItem key={lang} value={lang}>
                            {lang}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <br />
                  <Grid item xs={12} sx={{ textAlign: "right" }}>
                    <Box sx={{ mt: 4 }}>
                      <Button variant="contained" size="large" type="submit">
                        Save
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </MyPaper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const user = state.user;
  return { user };
};
export default connect(mapStateToProps)(AddPost);
