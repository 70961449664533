import { Typography, Button, Link } from "@mui/material";
import { styled } from "@mui/system";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Logout from "../components/general/Logout";
import RandomPicBanner from "../components/home/RandomPicBanner";
import { APP_NAME ,BASE_IMAGE  } from "../config/main.json";

const logo = `${BASE_IMAGE}logo-big.png`;

const AccountButton = styled(Button)({
  marginTop: "30px !important",
  textTransform: "none !important",
  fontSize: {
    lg: "30px !important",
  },
});

const Logo = styled("img")({});

const Home = () => {
  const userLoggedIn = useSelector((state) => state.user.loggedIn);

  return (
    <>
      <Helmet>
        <title>Aifluence - Influencer Marketing</title>
      </Helmet>
      <RandomPicBanner>
        <Logo
          src={logo}
          alt={APP_NAME}
          sx={{
            width: {
              xs: "70vw",
              sm: "35vw",
              md: "53vw",
              lg: "53vw",
              xl: "53vw",
            },
          }}
        />
        <Typography
          variant="h2"
          sx={{
            color: "white",
            marginTop: "10px !important",
            fontWeight: "200 !important",
            lineHeight: "1.5 !important",
            fontSize: {
              sm: "2vw !important",
              xs: "4.5vw !important",
            },
          }}
        >
          Powering campaign delivery{" "}
        </Typography>
        {!userLoggedIn ? (
          <Link to="/signin" component={RouterLink} color="inherit" underline="none">
            <AccountButton variant="contained" size="large">
              &nbsp;Sign In
            </AccountButton>
          </Link>
        ) : (
          <>
            <Link to="/campaigns/" component={RouterLink} color="inherit" underline="none">
              <AccountButton variant="contained" size="large">
                &nbsp;My Account
              </AccountButton>
            </Link>
            <br />
            <Logout>
              <Button sx={{ mt: 1 }}>Logout</Button>
            </Logout>
          </>
        )}
      </RandomPicBanner>
    </>
  );
};

export default Home;
