import { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MyPaper from "../../components/general/MyPaper";
import { BadgeTwoTone } from "@mui/icons-material";
import axios from "axios";
import { API_HOST } from "../../config/main.json";

function Translate() {
  const [backdropShow, setBackdropShow] = useState(true);
  const [langListing, setLangListing] = useState({ all: [] });
  const [langDetails, setLangDetails] = useState({});
  const [targetLangListing, setTargetLangListing] = useState([]);
  const [sourceLang, setSourceLang] = useState("auto");
  const [targetLang, setTargetLang] = useState("English");
  const [sourceText, setSourceText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    getTranslatorInfo();
  }, []);

  const getTranslatorInfo = () => {
    axios.get(API_HOST + "/translator/info").then((res) => {
      setLangListing(res.data.body.listing);
      setLangDetails(res.data.body.details);
      setTargetLangListing(res.data.body.listing.all);
      setBackdropShow(false);
    });
  };

  const translate = (event) => {
    event.preventDefault();
    const params = {};
    if (sourceLang === targetLang) {
      alert("Source language is same as target language");
      return;
    }
    if (
      (sourceLang === "auto" || sourceLang in langDetails.google) &&
      targetLang in langDetails.google
    ) {
      params.sourceLang =
        sourceLang === "auto"
          ? "auto"
          : langDetails.google[sourceLang].language;
      params.targetLang = langDetails.google[targetLang].language;
      params.translationSource = "google";
    } else if (
      (sourceLang === "auto" || sourceLang in langDetails.bing) &&
      targetLang in langDetails.bing
    ) {
      params.sourceLang =
        sourceLang === "auto" ? "auto" : langDetails.bing[sourceLang].language;
      params.targetLang = langDetails.bing[targetLang].language;
      params.translationSource = "bing";
    } else {
      alert("Some error occurred. Please check the console for more info");
      console.log(
        "This shouldn't have happened. We are very ashamed that it did. Somehow this combination of language is not working."
      );
      return;
    }

    let maxPartitionSize = 120;
    if (params.translationSource === "bing") {
      maxPartitionSize = 90;
    }

    setSourceText(sourceText.replace(/["]/g, ""));

    let text = sourceText
      .replace(/["]/g, "")
      .split("\n")
      .map((x) => x.trim())
      .filter((x) => x.length > 0);
    if (text.length === 0) {
      alert("Please input text to translate");
      return;
    }
    const partitionedSourceText = [];
    while (text.length > maxPartitionSize) {
      partitionedSourceText.push(text.splice(0, maxPartitionSize));
    }
    partitionedSourceText.push(text);
    params.sourceText = partitionedSourceText;

    setOutputText("");

    setBackdropShow(true);

    translateBatch(params, 0);
  };

  const translateBatch = (params, index) => {
    if (index >= params.sourceText.length) {
      setBackdropShow(false);
      return;
    }
    const data = {
      targetLang: params.targetLang,
      translationSource: params.translationSource,
      sourceText: params.sourceText[index],
    };

    if (params.sourceLang !== "" && params.sourceLang !== "auto") {
      data.sourceLang = params.sourceLang;
    }

    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(
        API_HOST + "/translator/translateText",
        { params: data },
        optionAxios
      )
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          let text = "";
          resp.data.body.forEach((para) => {
            text += para.translatedText + "\n";
          });
          const final = removeHTMLCharacterCode(text);
          setOutputText((value) => value + final);
          setReadOnly(true);
          translateBatch(params, index + 1);
        } else {
          alert("batch translation unsuccessful! Please try again");
          setBackdropShow(false);
          setOutputText("");
        }
      })
      .catch((err) => {
        alert("Some error occured. Please check console for more info!");
        setBackdropShow(false);
        console.log(err);
        setOutputText("");
      });
  };

  const removeHTMLCharacterCode = (text) => {
    let res = text;
    while (res.indexOf("&#") !== -1) {
      const start = res.indexOf("&#");
      const len = res.substring(start).indexOf(";") + 1;
      const num = res.substring(start + 2, start + len - 1);
      const htmlChar = res.substring(start, start + len);

      if (!isNaN(num) && num > 0 && num < 128) {
        const char = String.fromCharCode(num);
        res = res.replace(htmlChar, char);
      } else {
        res = res.replace("&#", "");
      }
    }

    return res;
  };

  const downloadOutput = () => {
    const ele = document.createElement("a");
    if (outputText === "") {
      alert("The output box is empty!!");
      return;
    }
    const inputArray = sourceText
      .split("\n")
      .map((x) => x.trim())
      .filter((x) => x.length > 0);
    const outputArray = outputText
      .split("\n")
      .map((x) => x.trim())
      .filter((x) => x.length > 0);

    if (inputArray.length !== outputArray.length) {
      alert("There is an issue with the result, please try again later!");
      return;
    }

    let fileText = "";
    for (let i = 0; i < inputArray.length; i++) {
      fileText += `"${inputArray[i]}","${outputArray[i]}"\n`;
    }
    const file = new Blob([fileText], { type: "text/csv" });
    ele.href = URL.createObjectURL(file);
    ele.download = "myFile.csv";
    document.body.appendChild(ele); // for firefox
    ele.click();
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: "column",
        }}
        open={backdropShow}
      >
        <CircularProgress color="inherit" />
        <Box sx={{ marginTop: "20px" }}>Loading... please wait.</Box>
      </Backdrop>
      <Typography variant="h3" sx={{ mb: 2, mt: 2 }}>
        Translator
      </Typography>
      <form onSubmit={translate}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MyPaper>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5" sx={{ mb: 2 }}>
                  <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
                  Translate
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  disabled={sourceText === "" && outputText === ""}
                  onClick={() => {
                    setSourceText("");
                    setOutputText("");
                    setReadOnly(false);
                  }}
                  sx={{ transform: "translateY(-10px)" }}
                >
                  Clear
                </Button>
              </Stack>
              <Divider />
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="source-label">Source Language *</InputLabel>
                    <Select
                      onChange={(event) => setSourceLang(event.target.value)}
                      variant="outlined"
                      label="Source Language "
                      value={sourceLang}
                      required
                    >
                      <MenuItem key="auto" value="auto" selected={true}>
                        Auto Detect
                      </MenuItem>
                      {langListing?.all.map((lang) => (
                        <MenuItem key={lang} value={lang}>
                          {lang}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="source-label">Output Language *</InputLabel>
                    <Select
                      onChange={(event) => setTargetLang(event.target.value)}
                      variant="outlined"
                      label="Output Language"
                      value={targetLang}
                      required
                    >
                      {targetLangListing.map((lang) => (
                        <MenuItem key={lang} value={lang}>
                          {lang}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      onChange={(event) => setSourceText(event.target.value)}
                      value={sourceText}
                      variant="outlined"
                      label="Input Text"
                      required={true}
                      multiline
                      rows={15}
                      inputProps={{ readOnly: readOnly }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      label="Output Text"
                      value={outputText}
                      multiline
                      rows={15}
                      inputProps={{ readOnly: true }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <br />
              <Divider />
            </MyPaper>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "right" }}>
            <Box sx={{ mt: 4 }}>
              {outputText !== "" && (
                <Button
                  variant="outlined"
                  size="large"
                  onClick={downloadOutput}
                  style={{ marginRight: "30px" }}
                >
                  Download
                </Button>
              )}
              <Button variant="contained" size="large" type="submit">
                Translate
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default Translate;
